@use "breakpoints";
@use "buttons";
@use "colors";
@use "layout";
@use "typography";

section.content {
  justify-content: flex-start;
}

.content {
  @include layout.module-content;
  @include layout.content-grid;

  padding-block: 4.8rem;

  article {
    grid-column: 1 / span 12;
  }

  h1,
  h2,
  h3 {
    color: colors.$black;
  }

  h1 {
    @include typography.small-headline;

    margin-block-end: 4.8rem;
  }

  p {
    color: colors.$dark-gray;
    margin-block-end: 0;
    white-space: pre-wrap;

    strong {
      color: colors.$black;
    }
  }

  input,
  label {
    accent-color: colors.$dark-gray;
    color: colors.$dark-gray;
    margin-block: 0;
    transition:
      background-color 0.2s ease-in-out,
      color 0.2s ease-in-out;

    &:hover {
      cursor: pointer;
    }
  }

  input:checked,
  label:has(input:checked) {
    accent-color: colors.$black;
    color: colors.$black;
  }

  label:has(input[type="checkbox"]),
  label:has(input[type="radio"]) {
    display: flex;
    flex-direction: row;
  }

  label:has(input[type="checkbox"]) {
    @include typography.body-small;

    align-items: flex-start;

    input {
      margin-inline-end: 1.6rem;
    }
  }

  label:has(input[type="radio"]) {
    align-items: center;

    input {
      margin-inline-start: auto;
    }
  }

  fieldset {
    legend {
      display: contents;

      span {
        @include typography.subhead-regular;

        color: colors.$black;
        display: block;
        margin-block-end: 4rem;
      }
    }

    &[data-has-error="true"] {
      legend span {
        color: colors.$error-red;
      }

      &:not(:has(legend)) {
        label {
          color: colors.$error-red;
        }
      }
    }

    + fieldset {
      border-block-start: 1px solid rgba(colors.$black, 0.15);

      &:not(:has(input[type="checkbox"])) {
        margin-block-start: 8rem;
        padding-block-start: 8rem;
      }

      &:has(input[type="checkbox"]) {
        margin-block-start: 4.8rem;
        padding-block-start: 3.2rem;

        @media (width >= breakpoints.$md) {
          margin-block-start: 8rem;
          padding-block-start: 4rem;
        }
      }
    }
  }

  input[type="checkbox"],
  input[type="radio"] {
    appearance: none;
    block-size: 2rem;
    border: 1px solid colors.$black;
    flex: 0 0 auto;
    inline-size: 2rem;
  }

  input[type="checkbox"] {
    border-radius: 0;
  }

  input[type="radio"] {
    border-radius: 50%;
    position: relative;

    &::after {
      background-color: colors.$white;
      border: 3px solid colors.$white;
      border-radius: 50%;
      content: "";
      inset: 0;
      position: absolute;
      transition: background-color 0.2s ease-in-out;
    }
  }

  input[type="checkbox"]:checked {
    background-color: colors.$black;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMy4zMSA5Ljk5Ij48cGF0aCBmaWxsPSIjZmZmIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMy4zMS44OCA0LjIgOS45OSAwIDUuNzlsLjg4LS44OEw0LjIgOC4yMyAxMi40MyAwbC44OC44OFoiLz48L3N2Zz4K");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 1.4rem 1rem;
  }

  input[type="radio"]:checked {
    &::after {
      background-color: colors.$black;
    }
  }

  input[type="text"][name="zipCode"] {
    border-block-start: unset;
    border-inline: unset;
    padding: 0.8rem 0;
  }

  input[type="text"],
  textarea {
    border: 1px solid rgba(colors.$black, 0.3);
    border-radius: 0;
    color: colors.$black;
    inline-size: 100%;
    padding: 1.6rem;
    resize: none;

    &:focus {
      outline: none;
    }

    &[required]:valid {
      border-color: colors.$black;
    }

    &::placeholder {
      color: colors.$medium-gray;
    }

    &[data-has-error="true"] {
      border-color: colors.$error-red;
    }
  }

  @media (width >= breakpoints.$md) {
    padding-block: 8rem;

    article {
      grid-column: 1 / span 8;
    }
  }

  button[type="submit"] {
    @include buttons.button(buttons.$theme-primary-dark, buttons.$width-wide);

    margin-block-start: 4.8rem;
  }
}
