@use "breakpoints";
@use "colors";
@use "typography";

.screen {
  .description {
    margin-block-end: 2.4rem;

    @media (width > breakpoints.$md) {
      margin-block-end: 4.8rem;
    }
  }

  .calendar {
    user-select: none;

    button {
      background-color: transparent;
      block-size: 4rem;
      border: none;
    }

    /* stylelint-disable selector-class-pattern */
    :global .react-calendar__navigation {
      display: flex;
      margin-block-end: 4rem;
    }

    :global .react-calendar__month-view__weekdays__weekday {
      display: none;
    }

    :global .react-calendar__navigation__label {
      @include typography.subhead-semi-bold;

      order: 0;
      text-align: start;
    }

    :global .react-calendar__navigation__arrow {
      order: 1;

      svg {
        block-size: 1.9rem;
        inline-size: 1.1rem;
      }

      &.react-calendar__navigation__next-button {
        margin-inline-end: 2%;
      }

      &.react-calendar__navigation__prev-button {
        svg {
          margin-inline-end: 4rem;
          transform: rotate(180deg);
        }
      }

      &:disabled svg {
        fill: colors.$gray;
      }
    }

    :global .react-calendar__tile {
      align-items: center;
    }

    :global .react-calendar__month-view__days__day {
      &:disabled {
        color: colors.$gray;
      }

      &.react-calendar__tile--active {
        background-color: colors.$black;
        clip-path: circle(closest-side);
        color: colors.$white;
      }
    }
    /* stylelint-enable selector-class-pattern */
  }
}
