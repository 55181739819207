@import "mixins";

.link-with-arrow {
  background-color: transparent;
  border: 0;
  padding: 0;
}

.link-white,
.link-black {
  font-size: 14px;

  a {
    outline: none;
  }

  svg {
    margin-inline-start: -5px;
    transition: margin-left 0.2s ease-in-out;

    path {
      transition: fill 0.2s ease-in-out;
    }
  }
}

.link-white {
  color: $white;

  a {
    @include text-button-states-white-border;
  }

  svg {
    path {
      fill: $white;
    }
  }
}

.link-black {
  color: $black;

  a {
    @include text-button-states-black-border;
  }

  svg {
    path {
      fill: $black;
    }
  }
}

.link-white:hover,
.link-black:hover {
  cursor: pointer;

  svg {
    margin-inline-start: 5px;
  }
}

.link-white:hover {
  color: $white;
}

.link-black:hover {
  color: $black;
}
