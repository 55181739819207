@use "colors";
@use "typography";
@import "custom";

.card-list-view {
  user-select: none;

  .inner-content {
    padding: 0;
  }

  .inner-container {
    border-block-start: 1px solid colors.$gray;
    padding: 0 24px;

    .row {
      display: flex;
      flex-wrap: wrap;
      padding: 32px 0;

      @include media-breakpoint-down(sm) {
        padding-block: 40px 34px;
      }

      .club-label {
        background-color: colors.$white;
        color: colors.$black;
        display: inline-block;
        padding: 4px;
        transition: all 0.28s linear;
      }
    }

    .headline {
      @include typography.subhead-regular;
    }

    .address {
      @include typography.body-small;

      margin-inline-end: 25px;

      @include media-breakpoint-up(lg) {
        margin-block-start: 16px;
      }

      @include media-breakpoint-down(md) {
        padding-block-start: 10px;
      }

      @include media-breakpoint-down(sm) {
        margin-inline-end: 0;
      }
    }

    .link-arrow {
      @include typography.cta-small;

      color: colors.$white;
      outline: none;

      svg {
        margin-inline-start: -5px;
        transition: all 0.2s ease-in-out;

        path {
          fill: colors.$white;
        }
      }

      &:focus,
      &:hover {
        svg {
          margin-inline-start: 3px;
        }
      }
    }

    .amenities {
      @include typography.body-small;

      margin-block-start: 16px;
    }

    ul {
      @include typography.body-small;

      padding-inline-start: 15px;
    }

    .img-container {
      max-block-size: 225px;
      overflow: hidden;

      @include media-breakpoint-down(md) {
        max-block-size: 150px;
      }

      @include media-breakpoint-down(sm) {
        max-block-size: 130px;
      }

      img {
        block-size: 100%;
        inline-size: 100%;
        object-fit: cover;
      }
    }
  }

  .active-club-item {
    background-color: colors.$white;
    color: colors.$black;

    .inner-container {
      border-block-start: 1px solid transparent;

      .row {
        .club-label {
          background-color: colors.$black;
          color: colors.$white;
        }
      }

      .link-arrow {
        color: colors.$black;

        svg {
          margin-inline-start: 3px;

          path {
            fill: colors.$black;
          }
        }
      }
    }
  }

  & > a {
    display: block;
    outline: none;
    transition: background-color 300ms linear;

    &:focus,
    &:hover {
      background-color: colors.$white;
      color: colors.$black;

      .inner-container {
        border-block-start: 1px solid transparent;

        .row {
          .club-label {
            background-color: colors.$black;
            color: colors.$white;
          }
        }

        .link-arrow {
          color: colors.$black;

          svg {
            margin-inline-start: 3px;

            path {
              fill: colors.$black;
            }
          }
        }
      }
    }
  }
}
