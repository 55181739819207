@use "breakpoints";
@use "colors";
@use "layout";
@use "typography";

.screen {
  .action {
    margin-block-start: 8rem;
  }
}

.digital {
  grid-column-end: span 12;
  margin-block-start: 4.8rem;
  padding-block: 2.4rem;
  position: relative;

  &::after {
    background-color: rgba(colors.$black, 0.07);
    content: "";
    inline-size: calc(100% + var(--layout-gutter) + var(--layout-gutter));
    inset: 0 0 -4.8rem calc(var(--layout-gutter) * -1);
    pointer-events: none;
    position: absolute;
  }

  .digital-title {
    @include typography.subhead-regular;
  }

  .digital-photo {
    aspect-ratio: 16 / 9;
    display: block;
    margin-block-start: 2.4rem;
    object-fit: cover;
  }

  .digital-description {
    margin-block-start: 2.4rem;
  }

  .digital-form {
    margin-block-start: 4.8rem;

    a {
      text-decoration: underline;
    }
  }

  button[type="submit"] {
    margin-block-start: 3.2rem;

    @media (width >= breakpoints.$md) {
      margin-block-start: 4rem;
    }
  }

  @media (width >= breakpoints.$md) {
    grid-column: 11 / span 6;
    margin-block-start: 0;
    padding-block-start: 8rem;
    padding-inline-start: 13%;

    &::after {
      inline-size: calc(100% + var(--layout-gutter));
      inset: -8rem 0;
    }
  }
}
