.screen {
  .description {
    a {
      text-decoration: underline;
    }
  }

  .action {
    margin-block-start: 8rem;
  }
}
