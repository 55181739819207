@use "colors";
@use "typography";
@import "mixins";
@import "custom";

.hero {
  min-block-size: 100vh;
  padding-block-end: 214px;
  position: relative;

  @include media-breakpoint-down(md) {
    block-size: auto;
    padding-block-end: 40px !important;
  }

  .container {
    margin: 0 auto;

    @include media-breakpoint-up(sm) {
      max-inline-size: 327px;
    }

    @include media-breakpoint-up(md) {
      max-inline-size: 672px;
    }

    @include media-breakpoint-up(lg) {
      max-inline-size: 1192px;
    }
  }

  .wrapper {
    block-size: 100%;
  }

  .header {
    align-items: center;
    background-color: transparent;
    block-size: 68px;
    inline-size: 100%;
    inset-block-start: 0;
    position: absolute;
    z-index: 400;

    @include media-breakpoint-down(sm) {
      padding: 0 14px;
    }

    @include media-breakpoint-up(md) {
      padding: 0 24px;
    }

    .row {
      background-color: transparent;
      padding: 10px auto;

      section {
        align-items: center;
        display: flex;
        inline-size: 100%;
        justify-content: space-between;
        padding-block-start: 20px;

        &.fixed-content {
          display: none;
        }

        .logo-image {
          block-size: auto;
          max-inline-size: 106px;
          object-fit: contain;
        }

        .link {
          color: colors.$white;
          text-decoration: underline;
        }

        .button {
          @include typography.cta-small;

          border: 1px solid colors.$black;
          color: colors.$black;
          padding: 0.6em 1.4em;
          text-decoration: none;
        }
      }
    }

    &.fixed {
      animation: slide-down 0.2s ease-out forwards;
      background: colors.$white;
      position: fixed;

      .row {
        section {
          &.fixed-content {
            display: flex;
            justify-content: space-between;
          }

          &.unfixed-content {
            display: none;
          }
        }
      }
    }
  }

  .background-image {
    block-size: 100%;
    inline-size: 100%;
    inset-inline: 0;
    position: absolute;

    img,
    video {
      block-size: 100%;
      inline-size: 100%;
      object-fit: cover;
      position: relative;
    }

    video {
      z-index: -1;
    }
  }

  .headline-wrapper {
    color: colors.$white;
    padding-inline-start: 0;
    padding-inline-start: 12px;
    word-break: break-word;

    @include media-breakpoint-up(md) {
      margin: 0;
    }

    @include media-breakpoint-down(md) {
      margin: 6em 0 0;
      max-inline-size: 100% !important;
      padding-block-end: 48px;
    }

    .headline {
      @include typography.xl-headline;

      margin: 0;
      padding-block-end: 24px;
      white-space: pre-wrap;
    }

    .sub-headline {
      @include typography.subhead-regular;

      margin: 0;
      white-space: pre-wrap;
    }
  }

  .min-height100 {
    display: flex;
    min-block-size: 100%;

    @include media-breakpoint-down(md) {
      block-size: auto;
    }
  }

  .form {
    background-color: colors.$white;
    color: colors.$off-black;
    margin-block: 140px 30px;
    min-block-size: 400px;
    -ms-overflow-style: none; // IE 10+
    padding: 40px;
    scrollbar-width: none; // Firefox

    @include media-breakpoint-down(sm) {
      margin: 0 auto 20px;
      max-block-size: inherit;
    }

    label {
      svg {
        margin-inline-start: -10px;
      }
    }

    .styled-text-input {
      &.input-mb {
        margin-block-end: 32px !important;
      }

      .text-label {
        @include typography.body-small;
      }
    }

    .last-text-input {
      margin-block-end: 2em;
    }

    .legal {
      margin: 1em;
    }

    .radio-label {
      cursor: pointer;
      display: inline-block;
      inline-size: 100%;
      margin: 0 auto;

      @include media-breakpoint-down(md) {
        inline-size: auto;
        margin-inline-end: 12px;
      }

      @include media-breakpoint-down(sm) {
        inline-size: 100%;
      }

      input[type="radio"] {
        display: none;
      }
    }

    &::-webkit-scrollbar {
      display: none;
      inline-size: 0;
    }

    .top {
      inset-block-start: 0;
      position: sticky;

      svg {
        inset-block-start: -35px;
        inset-inline-end: -40px;
        position: absolute;
        transform: rotate(90deg);
      }
    }

    .bottom {
      inset-block-end: 0;
      position: sticky;
      text-align: end;

      @include media-breakpoint-down(sm) {
        display: none;
      }

      svg {
        inset-block-end: -35px;
        inset-inline-end: -40px;
        position: absolute;
        transform: rotate(-90deg);
      }
    }

    h3 {
      @include typography.small-headline;

      color: colors.$black;
    }

    .sub-copy {
      @include typography.body-small;

      padding-block-end: 26px;

      &.member-advisor-contact-terms {
        @include typography.body-small;

        color: colors.$medium-gray;
        margin-block-start: 20px;
      }
    }

    input[type="submit"] {
      background-color: colors.$black;
      block-size: 52px;
      border: none;
      color: colors.$white;
      inline-size: 218px;

      &:disabled {
        background-color: colors.$light-gray;
        color: colors.$gray;
      }

      &:focus {
        outline: none;
      }
    }

    input[name="newsletter"] {
      display: none;
    }

    .nearest-clubs-container {
      margin: 0 auto;

      .location {
        color: colors.$medium-gray;
      }
    }
  }

  .other-location {
    display: block;
    margin-block-end: 2em;
    text-decoration: underline;
  }

  .error-message-style {
    color: colors.$error-red;
  }
}

.info-cta {
  color: colors.$medium-gray;
  padding: 20px 0;

  span {
    cursor: pointer;
    text-decoration: underline;
  }
}

.fine-print {
  @include typography.body-extra-small;

  color: colors.$dark-gray;
  padding: 1rem 0;

  a {
    text-decoration: underline;
  }
}

@keyframes slide-down {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

.name-fields {
  display: flex;
  gap: 24px;
}
