@use "colors";
@use "typography";

.screen {
  .sections {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .section {
    + .section {
      border-block-start: 1px solid rgba(colors.$black, 0.15);
      margin-block-start: 8rem;
      padding-block-start: 8rem;
    }
  }

  .section-title {
    @include typography.subhead-regular;

    margin-block-end: 2.4rem;
  }

  .section-text {
    margin: 0;
  }

  .list {
    list-style-position: inside;
    list-style-type: disc;
    margin-block-start: 2.4rem;
    padding: 0;
  }

  .list-item {
    + .list-item {
      margin-block-start: 0.8rem;
    }
  }
}
