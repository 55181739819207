@use "breakpoints";
@use "colors";
@use "typography";

.eqx-custom-select {
  margin: 20px 0;
}

.eqx-select {
  block-size: 48px;
  border: 1px solid #ccc !important;
  cursor: pointer;
  inline-size: 365px;
  padding: 10px;

  @media (width >= breakpoints.$md) {
    inline-size: 386px;
  }
}

.eqx-input {
  block-size: 48px !important;
  inline-size: 365px !important;
  padding: 5px;

  @media (width >= breakpoints.$md) {
    inline-size: 386px !important;
  }
}

.eqx-options {
  border: 1px solid #ccc;
  inline-size: 365px !important;
  max-block-size: 150px;
  overflow-y: auto;

  @media (width >= breakpoints.$md) {
    inline-size: 386px !important;
  }
}

.eqx-option {
  background-color: #fff;
  cursor: pointer;
  padding: 10px;
}

.eqx-no-data-text {
  color: #999;
  padding: 10px;
}
