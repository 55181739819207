@use "breakpoints";
@use "colors";
@use "typography";

.mail-to-action {
  @include typography.cta;

  color: colors.$black;
  display: inline-flex;
  flex-direction: row;
  inline-size: 100%;

  svg {
    block-size: 2.4rem;
    flex: 0 0 auto;
    inline-size: 2.4rem;
  }

  &:hover {
    text-decoration: underline;
  }
}

.compose-icon {
  margin-inline-end: 1.6rem;
  padding: 0.4rem;
}

.arrow-icon {
  margin-inline-start: auto;
  padding: 0.75rem;
}

@media (width >= breakpoints.$sm) {
  .mail-to-action {
    inline-size: auto;
  }

  .arrow-icon {
    margin-inline-start: 3.2rem;
  }
}
