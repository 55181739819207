@use "breakpoints";
@use "colors";
@use "layout";
@use "typography";

.screen {
  .sub-title {
    @include typography.subhead-regular;

    margin-block-end: 2.4rem;
  }

  .description {
    border-block-end: 1px solid rgba(colors.$black, 0.15);
    margin-block-end: 4.8rem;
    padding-block-end: 4.8rem;

    @media (width >= breakpoints.$md) {
      margin-block-end: 8rem;
      padding-block-end: 8rem;
    }
  }

  .question {
    label:has(input[type="file"]),
    label:has(input[type="text"]) {
      inline-size: 100%;
    }

    label:has(input[type="file"]) {
      align-items: center;
      border-block-end: 1px solid rgba(colors.$black, 0.15);
      display: flex;
      justify-content: space-between;
      padding-block-end: 0.8rem;

      input {
        display: none;
      }
    }

    label[data-filled="true"] {
      color: colors.$black;
    }

    @media (width >= breakpoints.$md) {
      @include layout.content-subgrid(8);

      .question-text,
      .helper-text {
        grid-column-end: span 8;
      }

      label:has(input[type="file"]),
      label:has(input[type="text"]) {
        grid-column-end: span 5;
      }
    }
  }

  .option {
    grid-column-end: span 8;

    + .option {
      margin-block-start: 2.4rem;
    }
  }

  .placeholder {
    color: colors.$dark-gray;
    padding-block-end: 0.8rem;
  }

  .helper-text {
    @include typography.body-extra-small;

    color: colors.$dark-gray;
    margin-block-start: 0.8rem;
  }

  .error-card {
    margin-block-start: 4.8rem;
  }
}
