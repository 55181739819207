@use "breakpoints";
@use "colors";
@import "mixins";

.search {
  margin-block-start: 25px;
  position: relative;

  .search-icon {
    inset-inline-start: -10px;
    position: absolute;

    g {
      fill: colors.$medium-gray;
    }

    @media (width >= breakpoints.$sm) {
      inset-inline-start: -8px;
      position: absolute;
    }
  }

  .delete-icon {
    inset-inline-end: -18px;
    position: absolute;

    g {
      fill: colors.$medium-gray;
    }

    &:focus {
      outline: none;
    }

    &:hover {
      cursor: pointer;
    }

    @media (width >= breakpoints.$sm) {
      inset-inline-end: -8px;
    }
  }

  input {
    background-color: colors.$black;
    block-size: 45px;
    border: none;
    border-block-end: 1px solid colors.$medium-gray;
    border-radius: 0;
    color: colors.$gray;
    font-size: 16px;
    inline-size: 100%;
    padding-inline-start: 25px;

    &:active,
    &:focus {
      border-block-end: 1px solid colors.$white;
      color: colors.$white;
      outline: none;

      ~ svg {
        g {
          fill: colors.$white;
        }
      }
    }

    @media (width >= breakpoints.$sm) {
      font-size: 16px;
      padding-inline-start: 40px;
    }
  }

  .pac-container::after {
    background-image: none !important;
    block-size: 0 !important;
    padding: 0 !important;
  }
}
