@use "breakpoints";
@use "colors";
@use "typography";

.screen {
  .description {
    border-block-end: 1px solid rgba(colors.$black, 0.15);
    margin-block-end: 4.8rem;
    padding-block-end: 4.8rem;

    @media (width >= breakpoints.$md) {
      margin-block-end: 8rem;
      padding-block-end: 8rem;
    }
  }

  .question:has(input[type="radio"]) {
    @include typography.cta;

    display: flex;
    flex-flow: row wrap;

    .question-text {
      flex: 1 0 100%;
      margin-block-end: 4rem;
    }

    .option {
      align-items: center;
      border: 1px solid colors.$black;
      color: colors.$black;
      display: flex;
      flex: 0 0 50%;
      justify-content: center;
      padding-block: 1.4rem;
      text-align: center;

      svg {
        block-size: 1.6rem;
        display: none;
        margin-inline-end: 0.4em;
        padding: 0.3rem 0.2rem;
      }

      + .option {
        border-inline-start: 0;
      }

      &:first-of-type {
        border-end-start-radius: 0.8rem;
        border-start-start-radius: 0.8rem;
      }

      &:last-of-type {
        border-end-end-radius: 0.8rem;
        border-start-end-radius: 0.8rem;
      }

      &:has(input:checked) {
        background-color: colors.$black;
        color: colors.$white;

        svg {
          display: block;
        }
      }

      @media (width >= breakpoints.$md) {
        flex-basis: 21.2rem;
      }
    }

    input {
      display: none;
    }
  }

  .freeze-select label {
    background-color: white;
    margin-block-start: -4px;
    padding: 0 4px;
  }

  .error-alert {
    margin-block-start: 4.8rem;
  }
}
