@use "breakpoints";
@use "colors";

.screen {
  .description {
    margin-block-end: 4.8rem;
  }

  .option,
  .details {
    + .option,
    + .details {
      margin-block-start: 2.4rem;
    }
  }

  .eqx-other-input {
    margin: 0 0 20px;
  }

  .zip-code {
    margin-block-start: 4rem;
  }

  .error-alert {
    margin-block-start: 4rem;
  }
}
