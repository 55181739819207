@use "variables";
@import "mixins";
@import "custom";

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slide-in {
  0% {
    inset-inline-start: -100%;
  }

  100% {
    inset-inline-start: 0;
  }
}

// #PLEASE NOTE# - The styles in the module are all
// mobile-first unlike most of the styles in the project.

.club-selector {
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  background-color: transparent;
  block-size: 100vh;
  border: 0;
  inline-size: 100vw;
  inset-block-start: 0;
  inset-inline-start: 0;
  margin: 0;
  max-block-size: none;
  overflow-y: auto;
  padding: 0;
  position: fixed;
  user-select: none;
  z-index: 100;

  &::backdrop {
    background-color: rgba(variables.$black, 0.9);
  }

  :global .pac-container {
    inset-block-start: 100% !important;
    inset-inline-start: 0 !important;
  }

  @include media-breakpoint-up(lg) {
    inline-size: 50vw;
  }
}

.main-content {
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  background-color: variables.$black;
  block-size: 100vh;
  display: block;
  inline-size: 100%;
  inset-inline-start: -100%;
  position: relative;
}

.close-btn {
  background-color: #fff;
  border: none;
  cursor: pointer;
  inset-block-start: 0;
  inset-inline-end: 0;
  outline: none;
  position: absolute;
  z-index: 2;
}

.backdrop {
  background-color: rgba(0 0 0 / 90%);
  block-size: 100%;
  inline-size: 100%;
  inset-block-start: 0;
  inset-inline-start: 0;
  position: absolute;
  z-index: 99;
}

.club-selector[open] {
  animation-name: fade-in;

  .main-content {
    animation-name: slide-in;
  }
}
