@use "breakpoints";
@use "colors";
@use "typography";

.screen {
  .sub-title {
    @include typography.subhead-semi-bold;

    margin-block-end: 2.4rem;
  }

  .description {
    color: colors.$dark-gray;
    margin-block-end: 4.8rem;

    @media (width >= breakpoints.$md) {
      margin-block-end: 8rem;
    }
  }

  .placeholder {
    color: colors.$dark-gray;
    padding-block-end: 0.8rem;
  }

  .proof-title {
    @include typography.subhead-semi-bold;

    color: colors.$black;
    margin-block-end: 8.8rem;
  }

  .textfield {
    input {
      border: unset !important; // For overriding
      padding: unset !important; // For overriding
    }
  }

  .helper-text {
    @include typography.body-extra-small;

    color: colors.$dark-gray;
    display: block;
    line-height: 150%;
    margin-block-start: 1rem;
  }

  .error-card {
    margin-block-start: 2.4rem;
  }
}
